<template>
    <div class="container">
        <div v-for="l in projectLevels" :key="l.id" class="type-item">
            <div class="type-item-left" @click="toCreateProject(l)">
                <div>{{ l.project_type }}</div>
                <div class="type-item-left-english">{{ dataMap[l.id].english }}</div>
            </div>
            <div class="type-item-right">
                <p v-for="(item, itemInd) in dataMap[l.id].desc" :key="itemInd">
                    {{item}}
                </p>
                <!-- <div class="type-item-right-title">最新公告</div>
                <div class="type-item-right-content">
                    <div v-for="(item, itemInd) in dataMap[l.id].list" :key="itemInd" class="type-item-right-li">
                        <span class="type-item-right-text">{{item.text}}</span>
                        <span class="type-item-right-time">{{item.time}}</span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            dataMap: {
                "3": {
                    english: 'SIGNATURE VERSION',
                    list: [
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' }
                    ],
                    desc: [
                        'UI设计创建画布页面限制≤1个，UI设计画布中按钮无限制，UI设计按钮添加指令时指令关联IP地址限制≤10个。',
                        'UI设计画布中所有组件 文字、按钮、图片、时间、环境组件、多图轮播、天气、矩阵、流媒体、视频、文档、生态组件 等 全部畅享使用无限制！',
                        '密钥绑定《签名版密钥》即可。密钥等级 旗舰版>尊贵版>签名版 高密钥兼容低版本！',
                        '购买或合作请添加首页企业微信联系我们。'
                    ]
                },
                "2": {
                    english: 'NOBLE EDITION',
                    list: [
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' }
                    ],
                    desc: [
                        'UI设计创建画布页面限制≤5个，UI设计画布中按钮无限制，UI设计按钮添加指令时指令关联IP地址限制≤30个。',
                        'UI设计画布中所有组件 文字、按钮、图片、时间、环境组件、多图轮播、天气、矩阵、流媒体、视频、文档、生态组件 等 全部畅享使用无限制！',
                        '密钥绑定《尊贵版密钥》即可。密钥等级 旗舰版>尊贵版>签名版 高密钥兼容低版本！',
                        '购买或合作请添加首页企业微信联系我们。并获取画布设计指导。'
                    ]
                },
                "1": {
                    english: 'FLAGSHIP EDITION',
                    list: [
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' },
                        { text: '2321363t673', time: '2022-02-13 16:00:00' }
                    ],
                    desc: [
                        'UI设计创建画布页面限制≤99个，UI设计画布中按钮无限制，UI设计按钮添加指令时指令关联IP地址限制≤999个。',
                        'UI设计画布中所有组件 文字、按钮、图片、时间、环境组件、多图轮播、天气、矩阵、流媒体、视频、文档、生态组件 等 全部畅享使用无限制！',
                        '密钥绑定《旗舰版密钥》即可。密钥等级 旗舰版>尊贵版>签名版 高密钥兼容低版本！',
                        '购买或合作请添加首页企业微信联系我们。并获取画布设计使用一对一项目指导。'
                    ]
                }
            }
        };
    },
    computed: {
        ...mapState(["loginAccountInfo", "projectLevels"]),
    },
    created() {
        this.$store.dispatch("getProjectLevelsAction", {});
    },
    methods: {
        toCreateProject(l) {
            const { pages, project_code, btns, ip_ports } = l;
            const limits = btoa(
                JSON.stringify({ project_code, pages, btns, ip_ports })
            );
            this.$router.push({
                path: "/projects/add",
                query: {
                limits,
                },
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.container {
    padding: 20px;
    .type-item {
        display: flex;
        margin-bottom: 20px;
        &:first-child {
            .type-item-left {
                background-image: url(../../assets/test.png);
            }
        }
        &:nth-child(2) {
            .type-item-left {
                background-image: url(../../assets/zungui.png);
            }
        }
        &:nth-child(3) {
            .type-item-left {
                background-image: url(../../assets/qijian.png);
            }
        }
        .type-item-left {
            width: 296px;
            height: 209px;
            padding: 30px;
            box-sizing: border-box;
            color: #fff;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            font-size: 19px;
            font-weight: 700;
            cursor: pointer;
            .type-item-left-english {
                font-size: 16px;
                opacity: .4;
                margin-top: 4px;
            }
        }
        .type-item-right {
            margin-left: 20px;
            background: #fff;
            flex: 1;
            border-radius: 10px;
            // padding: 0px 14px 16px;
            padding: 10px 15px;
            height: 209px;
            max-width: calc(100% - 316px);
            color: #818181;
            font-size: 13px;
            line-height: 26px;
            white-space: pre-wrap;
            p {
                text-indent: 10px;
                position: relative;
                &::before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #019cff;
                    position: absolute;
                    left: 0px;
                    top: 10px;
                }
            }
            .type-item-right-title {
                line-height: 40px;
                color: #019cff;
                position: relative;
                padding-left: 13px;
                &::before {
                    content: "";
                    width: 3px;
                    height: 14px;
                    background: #019cff;
                    position: absolute;
                    left: 0px;
                    top: 13px;
                }
            }
            .type-item-right-content {
                padding-left: 13px;
                overflow: auto;
                height: 153px;
            }
            .type-item-right-li {
                display: flex;
                font-size: 13px;
                line-height: 32px;
                border-bottom: 1px solid #eceef5;
                color: #747373;
                width: 100%;
                .type-item-right-text {
                    flex-grow: 1;
                    max-width: calc(100% - 200px);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .type-item-right-time {
                    width: 200px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    text-align: right;
                    color: #a6a6a6;
                }
            }
        }
    }
}
</style>